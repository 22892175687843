import React, { useState, useEffect } from "react";
import {
  Box,
  Link,
  Typography,
  Button,
  Modal,
  CircularProgress,
} from "@mui/material";
import Layout from "../../components/Layout";
import { MetaData } from "../../components/common/meta";
import { StaticImage } from "gatsby-plugin-image";
import { Link as RouterLink, PageProps, useStaticQuery, graphql } from "gatsby";
import EventTable from "../../components/Agenda";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import ArrowComponent from "../../components/thePit/Arrow";

import bg from "../../images/gamingWeek/bg.svg";
import bg2 from "../../images/gamingWeek/bg2.svg";
import bg3 from "../../images/gamingWeek/bg3.jpg";
import bg4 from "../../images/gamingWeek/bg4.jpg";
import bg5 from "../../images/gamingWeek/bg5.svg";
import bg6 from "../../images/gamingWeek/bg6.svg";
import logo from "../../images/gamingWeek/logo.svg";
import pit from "../../images/gamingWeek/pit.svg";
import google from "../../images/gamingWeek/google.svg";
import jinnie from "../../images/gamingWeek/jinnie.png";
import LocationIcon from "../../images/gamingWeek/location.inline.svg";
import Arrow from "../../images/arrow-right.inline.svg";
import CloseIcon from "../../images/x.inline.svg";
import play from "../../images/gamingWeek/play.svg";
import LinkedIn from "../../images/gamingWeek/linkedin.inline.svg";
import Twitter from "../../images/twitter.inline.svg";
import LinkIcon from "../../images/gamingWeek/link.inline.svg";

import anh from "../../images/gamingWeek/anh.png";
import wilsonwu from "../../images/gamingWeek/wilsonwu.png";
import dimitri from "../../images/gamingWeek/dimitri.png";
import gabriel from "../../images/gamingWeek/gabriel.png";
import jia from "../../images/gamingWeek/jia.png";
import michael from "../../images/gamingWeek/michael.png";
import nicky from "../../images/gamingWeek/nicky.png";
import paul from "../../images/gamingWeek/paul.png";
import rudy from "../../images/gamingWeek/rudy.png";
import akshay from "../../images/gamingWeek/akshay.png";
import alekseev from "../../images/gamingWeek/alekseev.png";
import idan from "../../images/gamingWeek/idan.png";
import walterlee from "../../images/gamingWeek/walterlee.png";
import tse from "../../images/gamingWeek/tse.png";
import duhamel from "../../images/gamingWeek/duhamel.png";
import cchang from "../../images/gamingWeek/cchang.png";
import samseo from "../../images/gamingWeek/samseo.png";
import gabriele from "../../images/gamingWeek/gabriele.png";
import shayan from "../../images/gamingWeek/shayan.png";
import derrick from "../../images/gamingWeek/derrick.png";
import oliverb from "../../images/gamingWeek/oliverb.png";
import pavelb from "../../images/gamingWeek/pavelb.png";
import lee from "../../images/gamingWeek/lee.png";
import kim from "../../images/gamingWeek/kim.png";
import wu from "../../images/gamingWeek/wu.png";
import chuyong from "../../images/gamingWeek/chuyong.png";
import wui from "../../images/gamingWeek/wui.png";
import bayly from "../../images/gamingWeek/bayly.png";
import wagner from "../../images/gamingWeek/wagner.png";
import yu from "../../images/gamingWeek/yu.png";
import aakshay from "../../images/gamingWeek/aakshay.png";
import tan from "../../images/gamingWeek/tan.png";
import benlee from "../../images/gamingWeek/benlee.png";
import lim from "../../images/gamingWeek/lim.png";
import dave from "../../images/gamingWeek/dave.png";
import aseev from "../../images/gamingWeek/aseev.png";
import jeff from "../../images/gamingWeek/jeff.png";
import jill from "../../images/gamingWeek/jill.png";
import max from "../../images/gamingWeek/max.png";
import alex from "../../images/gamingWeek/alex.png";
import chyiyan from "../../images/gamingWeek/chyiyan.png";
import robyj from "../../images/gamingWeek/robyj.png";
import pathak from "../../images/gamingWeek/pathak.png";

import altlayer from "../../images/gamingWeek/altlayer.svg";
import blocklords from "../../images/gamingWeek/blocklords.png";
import blowfish from "../../images/gamingWeek/blowfish.svg";
import bnbchain from "../../images/gamingWeek/bnbchain.svg";
import mixmob from "../../images/gamingWeek/mixmob.svg";
import mongodb from "../../images/gamingWeek/mongodb.svg";
import sui from "../../images/gamingWeek/sui.svg";
import upland from "../../images/gamingWeek/upland.svg";
import genun from "../../images/gamingWeek/genun.svg";
import ikigai from "../../images/gamingWeek/ikigai.svg";
import logosphere from "../../images/gamingWeek/logosphere.svg";
import xpla from "../../images/gamingWeek/xpla.svg";
import ygg from "../../images/gamingWeek/ygg.svg";
import ztx from "../../images/gamingWeek/ztx.svg";
import ambros from "../../images/gamingWeek/ambros.svg";
import ancient8 from "../../images/gamingWeek/ancient8.svg";
import anker from "../../images/gamingWeek/anker.svg";
import aptos from "../../images/gamingWeek/aptos.svg";
import bowled from "../../images/gamingWeek/bowled.svg";
import genopets from "../../images/gamingWeek/genopets.svg";
import gomble from "../../images/gamingWeek/gomble.svg";
import highstreet from "../../images/gamingWeek/highstreet.svg";
import klaytn from "../../images/gamingWeek/klaytn.svg";
import magicblok from "../../images/gamingWeek/magicbook.svg";
import metapixel from "../../images/gamingWeek/metapixel.svg";
import miraj from "../../images/gamingWeek/miraj.svg";
import myria from "../../images/gamingWeek/myria.svg";
import planetarium from "../../images/gamingWeek/planetarium.svg";
import ethlas from "../../images/gamingWeek/ethlas.svg";
import nofinality from "../../images/gamingWeek/nofinality.svg";
import okx from "../../images/gamingWeek/okx.svg";
import staratlas from "../../images/gamingWeek/staratlas.svg";
import web3auth from "../../images/gamingWeek/web3auth.svg";
import polygon from "../../images/gamingWeek/polygon.svg";
import sei from "../../images/gamingWeek/sei.svg";
import avalanche from "../../images/gamingWeek/avalanche.svg";
import supergaming from "../../images/gamingWeek/supergaming.svg";

import war from "../../images/gamingWeek/war.svg";
import solana from "../../images/gamingWeek/solana.svg";
import skymavis from "../../images/gamingWeek/skymavis.svg";
import nyan from "../../images/gamingWeek/nyan.svg";
import metapals from "../../images/gamingWeek/metapals.svg";
import metaengine from "../../images/gamingWeek/metaengine.svg";
import immutable from "../../images/gamingWeek/immutable.svg";
import chainstack from "../../images/gamingWeek/chainstack.svg";
import animoca from "../../images/gamingWeek/animoka.svg";
import ronin from "../../images/gamingWeek/ronin.svg";

export const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  p: "20px",
};
export const videoStyle = {
  backgroundColor: "rgba(0,0,0,1)",
  width: "100%",
  maxWidth: 1000,
  maxheight: "90%",
  position: "relative",
  boxShadow: "0 0 30px 0 rgba(0,0,0,2)",
};

export const prizeBox = {
  textAlign: "center",
  background: "#0C1F47",
  border: "1px solid #4B608D",
  position: "relative",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  height: "186px",
  width: { xs: "80%", sm: "23%" },
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "33px",
  justifyContent: "center",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#0C1F47",
    border: "1px solid #4B608D",
    left: "8.3px",
    top: "-8.3px",
    zIndex: -1,
    transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  "&:hover": {
    boxShadow: "0 0 20px 0 rgba(255, 255, 255, .3)",
    "&:before": {
      left: "0",
      top: "0",
    },
  },
};
export const projectBox = {
  textAlign: "center",
  background: "#0c1f47",
  border: "1px solid #4B608D",
  position: "relative",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  height: "100px",
  display: "flex",
  p: 1.5,
  alignItems: "center",
  justifyContent: "center",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#0c1f47",
    border: "1px solid #4B608D",
    left: "10px",
    top: "-10px",
    zIndex: -1,
    transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  "&:hover": {
    boxShadow: "0 0 20px 0 rgba(255, 255, 255, .3)",
    "&:before": {
      left: "0",
      top: "0",
    },
  },
};

export const projectBoxLogo = {
  display: "block",
  height: "44px",
  maxWidth: "110px",
  width: "auto",
  zIndex: 3,
  objectFit: "contain",
};

const th = {
  textAlign: "left",
  border: "solid #1CE7C2",
  borderWidth: "0 0 4px 0",
};
const td = {
  textAlign: "left",
  border: 0,
  width: "16.66%",
  padding: 0.8,
  "& span": {
    background: "#0F2553",
    minHeight: "89px",
    display: "flex",
    alignItems: "center",
    pl: 2.8,
    pr: 1.5,
    fontSize: 14,
  },
};
export const card = {
  background: "#0C1F47",
  border: "1px solid #4B608D",
  padding: 1.6,
  aspectRatio: "1/1.1",
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  overflow: "hidden",
  "& h6": {
    color: "#1CE7C2",
    mb: 0.5,
    position: "relative",
  },
  "& a": {
    position: "relative",
    display: "block",
  },
  "& p": {
    fontSize: 10,
    position: "relative",
    "& span": {
      display: "block",
    },
  },
};
const speakers = [
  {
    image: pathak,
    name: "Abhishek Pathak",
    company: [
      "Global Head ",
      "of Enterprise ",
      "Business at ",
      "FlutterFlow Inc. ",
    ],
    description: [
      "Abhishek is the Global Head of Enterprise Business at FlutterFlow. He oversees enterprise sales and marketing, solutions engineering, and other enterprise go-to-market functions.",
      "Previously, he worked at Google Cloud as a Customer and Partner Engineering Lead for the Business Applications Platform, where he focused on promoting, adopting, and growing Google's API Management offerings. Before Google, Abhishek led enterprise solution consulting teams at Oracle, NetApp, and Sun Microsystems.",
      "Abhishek holds an engineering degree in computer science from SASTRA University, India and a master's degree in management from IIM Ahmedabad, India.",
    ],
    twitter: null,
    linkedin: null,
  },
  {
    image: aakshay,
    name: "Akshay BD",
    company: [
      "Head of ",
      "International ",
      "Expansion ",
      "at Solana ",
      "Foundation",
    ],
    description: ["Taking Solana global."],
    twitter: "https://twitter.com/akshaybd",
    linkedin: null,
  },
  {
    image: akshay,
    name: "Akshay Khandelwal",
    company: ["Founder at ", "bowled.io"],
    description: [
      "Akshay Khandelwal is a proven startup leader and blockchain sector expert. He has launched multimillion-dollar businesses in media, content, and eCommerce for one of Asia's most successful unicorns, Sequoia Capital-backed ONE Championship. Prior to ONE, Akshay was a consultant with McKinsey & Co. where his focus was on commercial strategies and digital transformation for a variety of public and private sector clients.",
      "Akshay has also lived and worked in Africa and the UK to deploy a new multimillion-dollar product service line (PSL) for Halliburton. Akshay holds a bachelor's degree in Mechanical Engineering from IIT Madras, as well as an MBA from INSEAD. Outside of work, Akshay is passionate about cooking, investing, and spending time with kids (5 and 4 YO).",
    ],
    twitter: "https://twitter.com/AK_2AK",
    linkedin: "https://www.linkedin.com/in/akshaykhandelwal/",
  },
  {
    image: alex,
    name: "Alex Shalash",
    company: ["Co-Founder ", "at MetaEngine"],
    description: [
      "Alex Shalash is a Co-Founder and Co-CEO at MetaEngine. Operating the only cloud-based MMO game infrastructure since 2010, Alex is a Co-Founder at HeroEngine and @UEVO (SmartCity Engine). ",
      "Experienced IT consultant, research analyst, and fund manager with a M.Sc. in Physics and MBA from London Business School.",
    ],
    twitter: "https://twitter.com/AlexShalash",
  },
  {
    image: anh,
    name: "Anh Do",
    company: ["Executive ", "Producer at ", "GENUN Games"],
    description: [
      "Anh Do is an accomplished executive producer and game builder who has a passion for creating immersive and engaging gaming experiences that foster strong communities. As Executive Producer of GENUN Games, a subsidiary of Ikigai Technologies, she is responsible for overseeing the development of Web3 games that captivate and entertain. ",
      "Her role has been instrumental in shaping the direction of GENUN Games and establishing it as a key player in Web3 gaming.",
    ],
    linkedin: "https://www.linkedin.com/in/anhvdo/",
  },
  {
    image: tan,
    name: "Bailey Tan",
    company: ["Director of ", "Ecosystem ", "Strategy at ", "Ronin"],
    description: [
      "Bailey takes care of all aspects of the Ecosystem at Ronin—onboarding games, dApps and infra. Prior to Sky Mavis, he led primary and secondary investments at DeFiance Capital and is passionate about virtual economies and decentralized IP building.",
    ],
    twitter: "https://twitter.com/bottomd0g",
  },
  {
    image: benlee,
    name: "Benjamin Lee",
    company: [
      "Co-Founder ",
      "& Managing ",
      "Director at ",
      "Blowfish ",
      "Studios",
    ],
    description: [
      "Ben has 20+ years of experience in the computer software and 3D graphics industry and has extensive knowledge of all other aspects of software development. He has grown Blowfish Studios over 9 years into a globally competitive video game and virtual reality development house and has built great relationships with numerous technology providers and manufacturers such as Apple, Sony, Oculus, Valve, Microsoft, Nvidia, Google, and Samsung.",
      "The studio has created games featured worldwide and subsequently gathered a large client base for its development services, culminating in Blowfish winning an NSW Export award in 2016 for the Small Business category. His game development career started in 1998 when he worked on PC-based arcade games Thunderboats and Top Down Racer, for Perception Pty Ltd. Ben has a Bachelor of Engineering in Computer Systems Engineering.",
    ],
    twitter: "https://t.co/b5yXagBqJ4",
  },
  {
    image: tse,
    name: "Benjamin Tse",
    company: ["Co-Founder, CPO ", "at Genopets"],
    description: [
      "Benjamin is Co-Founder and Chief Product Officer at Genopets. He is an award-winning designer and entrepreneur with over 15 years of experience building products, platforms, and brands - with 7+ years specifically in Web3.",
      "Since founding a design agency at the age of 15, he’s launched multiple start-ups and was a winner of EOS’s global hackathon in 2018.",
    ],
    twitter: "https://twitter.com/benjamin_tse",
    linkedin: "https://www.linkedin.com/in/benjamin-tse/",
  },
  {
    image: duhamel,
    name: "Brendan Duhamel",
    company: ["Founder at Myria"],
    description: [
      "Brendan started his first blockchain-related company at 16, ten years ago, in the Bitcoin world and has been building in the industry ever since. Gaming is by far the most interesting use case he’s come across.",
    ],
    twitter: "https://twitter.com/brendan_duhamel",
    linkedin: "https://www.linkedin.com/in/brendan-d-4ba3b698",
  },
  {
    image: chyiyan,
    name: "Chyi Yan Hshieh",
    company: [
      "Senior Business ",
      "Development ",
      "Manager at ",
      "Polygon Labs",
    ],
    description: [
      "As Senior Business Development Manager at Polygon, Chyi Yan is responsible for the go-to-market strategy, execution, and support of deal flows for the Southeast Asian region, including China, Hong Kong, and Taiwan.",
    ],
    twitter: "https://twitter.com/CyCyhotpot",
  },
  {
    image: cchang,
    name: "Chris Chang",
    company: ["CBO at Gomble"],
    description: [
      "As CBO of Gomble, Chris leads all business functions, including blockchain strategy and migration to Web3 leveraging 111%'s outstanding expertise in casual game development.",
      "Prior to Gomble, Chris was Gaming Industry Lead at Google, providing comprehensive consulting services to over 500 gaming developers regarding monetization, marketing, and business development for 7+ years. He is a graduate of Seoul National University.",
    ],
    twitter: null,
    linkedin: "https://kr.linkedin.com/in/junyoung-chang-a2355b82",
  },
  {
    image: chuyong,
    name: "Chuyong Liu",
    company: ["Senior PM at ", "OKX Web3 ", "Wallet"],
    description: ["PM at OKX Web3 Wallet"],
    twitter: null,
    linkedin: null,
  },
  {
    image: lim,
    name: "Daryl Lim",
    company: ["COO at ", "MetaPals"],
    description: [
      "Daryl is an innovation leader who believes in constantly reinventing the way we experience the world and beyond—by introducing new methods, ideas and products. ",
      "He is currently the Co-Founder at MetaPals, a blockchain startup on a journey to help users transition from Web2 to Web3 through digital pets in the metaverse.",
      "Before MetaPals, Daryl was the Innovation & Partnerships Lead at Padang & Co. He handles corporate innovation consulting and partnerships engagement matters for corporate innovation projects—from digital transformation strategy, startup scouting, innovation challenges, accelerators, innovation labs, and tech hiring.",
    ],
    twitter: "https://twitter.com/daryllirl",
    linkedin: null,
  },
  {
    image: dave,
    name: "Dave Shin",
    company: [
      "Head of  ",
      "Business",
      "Development, ",
      "APAC at ",
      "Immutable",
    ],
    description: [
      "David Shin is an entrepreneur and ex-Banker who has been involved in several startups around the world. He is also an active community builder who has helped set up nonprofit organizations in Asia to ensure proper engagement with local/regional entities and officials as it relates to crypto currencies and blockchain technology. ",
      "David joined Immutable in December 2022, a Web3 startup focused on gaming and NFTs. He is the head of business development for both Asia and EMEA regions. David is considered to be an early adopter of digital currencies which has led him to appear on regional and global media outlets like CNN, CNBC, Bloomberg, NY Times, Swiss TV, and The Economist.",
    ],
    twitter: null,
    linkedin: null,
  },
  {
    image: derrick,
    name: "Derrick Chua",
    company: ["Team Lead, ", "Solutions ", "Architect at ", "MongoDB"],
    description: [
      "Derrick is obsessed with helping customers go fast in delivering valuable software to their customers because it makes things great for everyone. He has been working on modern application architectures, development methodologies, and cloud platforms for the last few years.",
      "He joined MongoDB in August 2020 to help developers working on modern applications to work more productively with data and to help database engineers move into the new world of operating distributed systems at scale. Derrick was, first and foremost, a developer and remains fascinated by how software has been solving the world’s problems.",
    ],
    twitter: null,
  },
  {
    image: dimitri,
    name: "Dmitri Safine",
    company: ["CTO at ", "Logosphere"],
    description: [
      "Dmitri Safine is the CTO at Ikigai Technologies, where he specializes in data modeling, online reporting, and analysis using business intelligence tools. He also has a proven track record for translating business requirements into scalable solutions and building metrics and business cases for informed decision-making.",
      "Prior to joining Ikigai Technologies, Dmitri was a data engineer for Amazon and a senior solutions architect for Agile GCP Labs.",
    ],
    twitter: null,
    linkedin: "https://www.linkedin.com/in/dsafine/",
  },
  {
    image: alekseev,
    name: "Evgenii (Eugene) Alekseev",
    company: ["CTO at Mirage"],
    description: [
      "Evgenii (Eugene) Alekseev is a game developer experienced in both indie and enterprise development. He is currently leading development at Mirage.",
    ],
    twitter: null,
    linkedin: "https://www.linkedin.com/in/alekseeveg/",
  },
  {
    image: aseev,
    name: "Evgenii (Eugene) Aseev",
    company: ["Founder ", "and CTO at ", "Chainstack"],
    description: [
      "Evgenii “Eugene” Aseev is the Founder and CTO at Chainstack, the leading suite of services connecting developers with Web3 infrastructure. With over a decade of experience in the tech industry, Eugene is a highly skilled leader and entrepreneur with a passion for building customer-first products. ",
      "He has successfully led and managed high-performing, world-class teams that are able to deliver innovative solutions to complex problems. Prior to founding Chainstack, Eugene gained extensive experience in the field of cybersecurity as a software engineer and researcher. ",
      "He holds a degree in computer science and has a deep technical knowledge of distributed systems and cybersecurity. In his personal life, Eugene is a proud father of a beautiful girl and enjoys hiking and skiing in his free time.",
    ],
    twitter: "https://twitter.com/toxzique",
  },

  {
    image: gabriel,
    name: "Gabby Dizon",
    company: ["Co-Founder at ", "Yield Guild ", "Games"],
    description: [
      "Gabby Dizon is a game industry veteran with over 20 years of experience. He co-founded Yield Guild Games (YGG), the world’s first and biggest Web3 gaming guild where players can enrich themselves as they find their community, discover games, and level up together.",
      "Gabby is also a founding board member of the Blockchain Game Alliance. His mission is to onboard millions of gamers so they can find opportunities in the digital world, and he believes the future of work is in the open metaverse.",
    ],
    twitter: "https://twitter.com/gabusch",
    linkedin: "https://ph.linkedin.com/in/gabbydizon",
  },
  {
    image: gabriele,
    name: "Gabriele Picco",
    company: ["Co-Founder & CTO at ", "Magicblock"],
    description: [
      "Gabriele is the CTO and Co-Founder at Magicblock. He has made several publications in top-tier AI conferences, such as NeurIPS, ACL, EMNLP, and filed more than 19 patents while working as a Research Engineer for IBM.",
      "A passionate game maker, Gabriele began his hacking career building mobile games. At Magicblock, he is leading the infrastructure and protocol development effort.",
    ],
    twitter: "https://twitter.com/PiccoGabriele",
    linkedin: "https://www.linkedin.com/in/gabriele-picco/",
  },
  {
    image: idan,
    name: "Idan Zuckerman",
    company: ["Co-Founder & ", "Co-Ceo at ", "Upland"],
    description: [
      "Idan Zuckerman is the Co-Founder and Co-CEO at Upland, a metaverse mapped to the real world and powered by the blockchain and NFT-based economy.",
      "Upland is one of the most widely adopted blockchain-based applications in the world and is available to play across devices, including the Apple App Store and Google Play Store.",
      "An entrepreneur and 20-year veteran of the gaming, blockchain, consumer, and internet industries, before co-founding Upland, Idan was a member of the founding team at Rocketplay, a leading mobile games developer that was subsequently acquired by AGS (NYSE:AGS).",
    ],
    twitter: "https://twitter.com/Idanzuck",
  },
  {
    image: bayly,
    name: "James Bayly",
    company: ["COO at ", "OnFinality & ", "SubQuery"],
    description: [
      "Well-versed across the business, from product engineering to being the face of both OnFinality and SubQuery, James is also a serial entrepreneur leading several tech companies.",
      "When he’s not in Berlin sipping cocktails, you’ll find him hiking up the Routeburn Track in New Zealand.",
    ],
    twitter: "https://twitter.com/JamesABayly",
    linkedin: null,
  },
  {
    image: kim,
    name: "JC Kim",
    company: ["CEO at ", "Planetarium ", "Labs"],
    description: [
      "JC Kim, Planetarium Labs CEO and former Tech Lead at LINE Blockchain, is not just a Forbes 30 Under 30 laureate; he's also a revolutionary force in the Web3 gaming scene.",
      "Having pioneered Nine Chronicles, the top-ranked on-chain game on DappRadar, he's crafted a free-to-play, open-source, decentralized MMORPG that boasts around 20,000 daily active gamers.",
      "Deeply involved in over 10 Web3 game projects, JC lives his mission of daily revolutionizing decentralized gaming.",
    ],
    twitter: "https://twitter.com/jckdotim",
    linkedin: "https://www.linkedin.com/in/jckdotim/",
  },
  {
    image: jeff,
    name: `Jeff "Jihoz" Zirlin`,
    company: ["Co-Founder ", "at Sky Mavis"],
    description: [
      `Jeff AKA "Jihoz", is a Co-Founder of Sky Mavis, the creators of Axie Infinity. Axie is the #1 NFT game by DAU, marketplace volume, and cultural impact. `,
      "As Chief Growth Officer, his mission is to grow the size and strength of both the community and economy. Jeff grew up playing video games and catching insects. His academic interests include military history and monetary policy.",
    ],
    twitter: "https://twitter.com/Jihoz_Axie",
    linkedin: null,
  },
  {
    image: jia,
    name: "Dr. Jia Yaoqi",
    company: ["Founder at ", "AltLayer"],
    description: [
      "Dr. Jia Yaoqi is the Founder of AltLayer, an elastic scaling protocol for Web3 with rollup-as-a-service. Before 2022, he was the director of Parity Asia and Co-Founder at Zilliqa. He was selected to the Forbes 30 Under 30 Asia list, 2019. Meanwhile, he drove the collaboration with Xfers on the stablecoin initiative for Southeast Asia StraitsX, as well as participated in blockchain projects with Mindshare, Singapore Exchange, Hg Exchange, etc. ",
      "He got his Ph.D from the National University of Singapore. His research work has been published in top international conferences, such as CCS and USENIX Security. His work was acknowledged by Google and Apple, and has received media coverage including Bitcoin Magazine, Forbes, and CNBC.",
    ],
    twitter: "https://twitter.com/jiayaoqi",
    linkedin: "https://www.linkedin.com/in/jiayaoqi/",
  },
  {
    image: jill,
    name: "Jill Priya Keshyap",
    company: [
      "Director of ",
      "Product and ",
      "Partnerships ",
      "at Animoca ",
      "Brands",
    ],
    description: ["Jill invests in, builds, and launches hit games."],
    twitter: "https://twitter.com/jillander91",
    linkedin: null,
  },
  {
    image: jinnie,
    name: "Jinnie Lee",
    company: ["Head of ", "Global ", "Partnerships ", "at Klaytn"],
    description: [
      "Jinnie Lee is a Blockchain and Web3 Specialist with extensive experience in business development and global partnership management in the blockchain, cryptocurrency, NFT, banking, and finance industries.",
      "She is now the Head of Global Partnerships at Klaytn Foundation, a non-profit organization established to accelerate the global adoption and ecosystem maturity of the Klaytn blockchain.",
      "Jinnie has an excellent track record from front to back office and sell-side to buy-side. She also has an outstanding aptitude in relationship management and has made notable contributions during her time at software companies and banks like Bloomberg L.P., Barclays, Goldman Sachs, SMBC, and Australia and New Zealand Banking Group.",
      "An exceptional thought leader with expertise in blockchain technology, digital assets, and various enterprise segments, Jinnie spoke on panels during the Singapore Blockchain Week 2022 on building the next generation of Web3 start-ups.",
      "Jinnie holds a Master’s of Business Administration from both Singapore Management University and IE Business School.",
    ],
    twitter: null,
    linkedin: "https://www.linkedin.com/in/jinnieleesg/?originalSubdomain=sg",
  },
  {
    image: lee,
    name: "Joseph Lee",
    company: ["Business Director at ", "METAPIXEL"],
    description: [
      "Joseph Lee serves as the Business Director at METAPIXEL, an innovative Web3 gaming ecosystem. He manages all business aspects of this pioneering Web3 venture, utilizing his past leadership experience in prominent gaming companies to guide strategic partnerships and stimulate business growth.",
      "His comprehensive knowledge of the gaming industry, combined with his acute business insight, is instrumental in propelling METAPIXEL to the forefront of Web3 gaming.",
    ],
    twitter: "https://twitter.com/0xjosephlee",
    linkedin: "https://www.linkedin.com/in/joseph-lee-330b0b90/",
  },
  {
    image: max,
    name: "Max Fu",
    company: ["CEO at Nyan  ", "Heroes"],
    description: [
      "Max is the Founder and CEO at Nyan Heroes. He is an award-winning entrepreneur with over 10 years of experience across strategy, product, and operations in the digital media and gaming space. ",
      "An expert in delivering immersive experiences to audiences, he has built communities of 30M+ across multiple global platforms, collaborating with brands like Google, Netflix, and Samsung.",
    ],
    twitter: "https://twitter.com/maxmerro",
    linkedin: null,
  },
  {
    image: michael,
    name: "Michael Yagi",
    company: ["CEO at Ikigai ", "Technology"],
    description: [
      "Michael Yagi is the CEO at Ikigai Technologies. Under his leadership, Ikigai has gone from a self-funded team to a $100 million dollar-valued startup in less than a year.",
      "His passion for innovation and technology is a key driving force behind the company’s three core offerings: Logosphere, enabling data integrity and quality; Grabbit, a creator-focused auction marketplace; and GENUN Games, a game studio focused on user experience. ",
    ],
    twitter: "https://twitter.com/ikigai_myagi",
    linkedin: "https://www.linkedin.com/in/michaelyagi/",
  },
  {
    image: wagner,
    name: "Michael Wagner",
    company: ["Co-Founder  ", "and CEO at ", "Star Atlas"],
    description: [
      "Michael Wagner, CFA, CEO at ATMTA, Inc. and the Co-Founder of its flagship product Star Atlas, is a serial entrepreneur and founder with a formal background in capital markets analysis, economics, and valuation methods.",
      "He has founded multiple companies centered around the emerging blockchain and cryptocurrency industry, the most recent of which is Star Atlas.",
      "Collectively, he possesses 10+ years in capital markets analysis, holds his Chartered Financial Analyst designation, 7+ years in blockchain, 24+ years in gaming, and 5+ years in executive leadership.",
    ],
    twitter: "https://twitter.com/at_mwagner",
    linkedin: null,
  },
  {
    image: nicky,
    name: "Nicky Li",
    company: ["COO at ", "Blocklords"],
    description: [
      "Nicky Li is the Chief Operating Officer and Co-Founder of Metaking Studios. A devout technologist with extensive experience in the gaming industry, he has established multiple successful game studios in China including Yoozoo, Forgame, and Moontoon.",
      "Currently, Nicky leads production efforts for BLOCKLORDS and is committed to using his expertise and passion to create innovative and engaging games that push the boundaries of what is possible.",
    ],
    twitter: "https://twitter.com/nickyli37",
    linkedin:
      "https://www.linkedin.com/in/nicky-li-788331163/?originalSubdomain=cn",
  },
  {
    image: oliverb,
    name: "Oliver Barker",
    company: ["Grant Program ", "Manager at ", "Sui ", "Foundation"],
    description: [""],
    twitter: "https://twitter.com/Ollie__barker",
  },
  {
    image: paul,
    name: "Paul Kim",
    company: ["CEO at XPLA"],
    description: [
      "Paul Kim is a results-driven executive with over 10 years of experience in finance and analytics. As the CEO at XPLA, he spearheads ecosystem expansion through strategic partnerships and investments. ",
      "Prior to founding XPLA, Paul held various leadership roles at eBay, where he demonstrated his expertise in financial planning and analytics. He is a graduate of the University of Notre Dame, where he earned his BA in Finance. ",
    ],
    twitter: "https://twitter.com/pkhr2013",
    linkedin: "https://www.linkedin.com/in/paul-kim-a1aa7a39/",
  },
  {
    image: pavelb,
    name: "Pavel Bains",
    company: ["Executive ", "Producer at ", "MixMob"],
    description: [
      "Pavel has a background in gaming and finance before entering crypto in 2016. He managed game studios for Disney and worked with Nintendo, Electronic Arts, Activision, and Xbox.",
      "Pavel was active in everything from general management to business development, licensing, and M&A. As Co-Founder and Executive Producer at MixMob, Pavel oversees strategy, financing, and partner relationships.",
    ],
    twitter: "https://twitter.com/pavelbains",
  },
  {
    image: robyj,
    name: "Roby John",
    company: ["CEO and ", "Co-Founder at ", "SuperGaming"],
    description: [
      "Roby is the CEO and Co-Founder at SuperGaming. Roby and his team are building Indus — a new Battle Royale set in an Indo-Futuristic universe and Tower Conquest: Metaverse Edition, SuperGaming's first Web3 Game.",
    ],
    twitter: "https://twitter.com/robytj",
  },
  {
    image: rudy,
    name: "Rudy Lee",
    company: ["Co-Founder and Co-CEO ", "at ZTX"],
    description: [
      "Rudy Lee is the Co-Founder and Co-CEO at ZTX, a Web3 metaverse platform empowering creators and communities to build, play, and earn. He currently leads all business aspects of ZTX and ZEPETO, Asia’s largest metaverse platform with over 400 million lifetime users.",
      "Rudy also oversees Sticker.ly, the world’s largest UGC sticker publishing platform with over 30 million MAU and 8+ billion user-generated stickers downloaded by consumers for use on WhatsApp and iMessage.",
    ],
    twitter: "https://twitter.com/_nonfungible",
    linkedin: "https://www.linkedin.com/in/rudyclee/",
  },
  {
    image: samseo,
    name: "Dr. Sam Seo",
    company: ["Representative ", "Director at ", "Klaytn ", "Foundation"],
    description: [
      "Dr. Sam Seo is the Representative Director at Klaytn Foundation, a non-profit organization that was established to accelerate global adoption and ecosystem maturity on the Klaytn blockchain. Prior to joining Klaytn Foundation, Dr. Seo was Chief Klaytn Officer (CKO) at Krust Universe where he led the development and operation of the Klaytn blockchain.",
      "He also worked as CTO at GroundX, a blockchain subsidiary of the leading South Korean mobile platform, Kakao, and as an assistant computer scientist at Argonne National Laboratory. He holds a B.S. degree in computer science and engineering as well as a Ph.D. in electrical engineering and computer science from Seoul National University. ",
      "He has authored numerous papers on parallel programming and high-performance computing. His technical interests include blockchains, high-performance computing, parallel programming models, compilers, and runtime systems.",
    ],
    twitter: "https://twitter.com/seo_sangmin",
    linkedin: "https://www.linkedin.com/in/sangmin-seo/",
  },
  {
    image: shayan,
    name: "Shayan Sanjideh",
    company: ["Developer ", "Relations ", "Engineer at ", "Sui ", "Foundation"],
    description: ["Developer Relations Engineer at Sui Foundation."],
    twitter: "https://twitter.com/0xShayan",
    linkedin: "https://www.linkedin.com/mwlite/in/shayan-sanjideh",
  },
  {
    image: wu,
    name: "Stanley Wu",
    company: ["CTO and Co-Founder ", "at Ankr"],
    description: [
      "Stanley Wu is the Co-Founder and CTO of Ankr. He commands over a decade of experience developing solutions for large-scale cloud computing services, including brands like Amazon. ",
      "Stanley is exceptionally skilled in engineering for web services, decentralized ledger technology, and decentralized finance. ",
      "At Ankr, he’s the driving force behind combining cloud computing and blockchain for innovations such as Elastic Blockchain RPC, Data & Web3 API, and the scalability and capacity of multichain solutions.",
    ],
    twitter: "https://twitter.com/stanleywu84",
    linkedin: "https://www.linkedin.com/in/stanley-w-5714a4163/",
  },
  {
    image: walterlee,
    name: "Walter Lee",
    company: [
      "Business ",
      "Development ",
      "and Web3 ",
      "Gaming ",
      "at BNB Chain",
    ],
    description: ["Business development and Web3 gaming."],
    twitter: "https://twitter.com/lclwalter",
  },
  {
    image: wilsonwu,
    name: "Wilson Wu",
    company: ["Asia Growth  ", "Lead at Ava  ", "Labs/Avalanche"],
    description: [
      "Wilson serves as Asia Growth Lead at Ava Labs. Prior to this, he was the Asia Head at FBG, leading the sales and trading team.",
      "Before joining FBG, Wilson founded a fintech startup where he provided fixed-income asset management SAAS services to top financial institutions. He was the first of few to dive into institutional-grade microlending via Trust - FOTIC, a subsidiary of Sinochem.",
      "As Business Director, he managed a fixed-income portfolio of over $500mil. Wilson also leads the global trading team at China International Futures. And he gained his accounting knowledge from Ernst & Young back in Singapore.",
    ],
    twitter: null,
  },
  {
    image: wui,
    name: "Wui Ngiap",
    company: ["CEO and ", "Co-Founder ", "at Ethlas"],
    description: [
      "Foo Wui Ngiap is a tech founder who has gained extensive experience in building tech companies through his career. He has had the privilege of serving as Grab's Head of Technology, where he oversaw Integrity, Mobility Engineering, and Grab Patent Office. Wui is also a seed investor in various tech companies as he is a firm believer in growing the ecosystem.",
      "Wui holds a Bachelor of Engineering in Electrical Engineering from the National University of Singapore and has over a decade of experience working with organisations such as Credit Suisse, Google, and Grab. At Grab, Wui led the development and management of large tech and operations teams across various domains, including Mobility Engineering, Trust & Safety, Growth, AI, and Financial Risk.",
      "Wui has always been passionate about gaming and security and is excited by the potential of decentralized technologies and the impact it can bring to the world. He strongly believes that Ethlas has the potential to bridge the gap and onboard the next billion users into Web3, blockchain and the cryptoverse.",
    ],
    twitter: "https://twitter.com/0xWui",
    linkedin: null,
  },
  {
    image: yu,
    name: "Zhen Yu Yong",
    company: ["Co-Founder  ", "and CEO ", "at Web3Auth"],
    description: [
      "Zhen Yu Yong is the CEO and Co-Founder at Web3Auth, the leading self-custodial auth infra company for dApps and web3 wallets.",
      "Prior to Web3Auth, Zhen worked on various Ethereum Foundation projects as a researcher for off-chain scalability — where he built one of the first cross-chain bridges called The Peace Bridge, between ETH and ETC.",
    ],
    twitter: "https://twitter.com/zenzhenyu",
    linkedin: null,
  },
];

const companies = [
  {
    image: altlayer,
    description: [
      "AltLayer is a Rollup-as-a-Service (RaaS) platform for decentralized application developers to create highly scalable application-tailored execution layers (Layer-2s). The platform provides support for deployment, testing, and monitoring, allowing developers to focus on building great decentralized applications.",
      "With AltLayer, developers can launch scalable Layer-2 solutions with low fees, fast confirmation times, and high security leveraging the Ethereum ecosystem.",
    ],
    link: "https://www.altlayer.io/",
  },
  {
    image: ambros,
    description: [
      "Ambros Technology is an innovative tech company in Singapore, providing the latest cutting-edge technology solutions to support the fast-growing sector of the video game industry.",
    ],
    link: "https://www.ambrostech.com/",
  },
  {
    image: ancient8,
    description: [
      "Ancient8 is developing the marketing and community infrastructure for Web3 Gaming. This includes building a Web3 Gaming Loyalty Platform and Launchpad products to help metaverse projects to onboard millions of users to Web3.",
      "Ancient8 manages Vietnam’s largest blockchain gaming guild and builds blockchain software products, educational content, institutional-grade research, and community. Ancient8’s vision is to democratize social and financial access in the Metaverse. ",
      "Ancient8 has raised $10M in total financing from leading investors including Pantera, Dragonfly, Hashed, Makers Fund, C² Ventures, Mechanism, Coinbase, IOSG, Animoca, among others. ",
    ],
    link: "https://www.ambrostech.com/",
  },
  {
    image: animoca,
    description: ["Building the open Metaverse."],
    link: "https://www.animocabrands.com/",
  },
  {
    image: anker,
    description: [
      "Ankr is an all-in-one Web3 developer hub that provides a full suite of tools to build Web3 apps and powers them with high-performance connections to 30+ blockchains.",
      "Ankr's mission is to power the next wave of Web3 innovation by providing affordable, secure, and distributed blockchain infrastructure.",
    ],
    link: "https://www.ankr.com/",
  },
  {
    image: aptos,
    description: [
      "Aptos is a Layer-1 blockchain created for safe development and built with user experience as a core focus.",
    ],
    link: "https://aptoslabs.com/",
  },
  {
    image: avalanche,
    description: ["Create without limits."],
    link: "https://www.avalabs.org/",
  },
  {
    image: blocklords,
    description: [
      "BLOCKLORDS is a player-driven medieval grand strategy game where your decisions and skills shape the world and narrative.",
      "Choose from several playstyles, including farming, fighting, resource management, and ruling, and forge your own destiny as a hero.",
      "With innovative gameplay mechanics and a player-powered economy, the question remains: Will you reap the land, wreak havoc, forge alliances, or go it alone? The choice is yours.",
    ],
    link: "https://www.blocklords.com/",
  },
  {
    image: blowfish,
    description: [
      "Blowfish Studios certainly has their favorite games they like to play and make, but they love creating games in general. Because of this they’re able to put all of their passion, energy, and creativity into every game they develop. Whether it's their own idea or one for a client, they believe in making the best game possible.",
      "Everyone at Blowfish is driven to create fun and enjoyable games that all types of people will play. They strive for technical and artistic excellence, all while building relationships with their clients and partners—all working together to create games that compete with the best in the world for many different gaming platforms.",
    ],
    link: "https://www.blowfishstudios.com/",
  },
  {
    image: bowled,
    description: [
      "Bowled.io is a sports-based social GameFi platform. Bowled.io operates 10+ live and non-live hyper-casual and mid-core strategy games around sports with a unified economy and reward system. Starting with Cricket (a massive 2.5B followership), bowled.io aims to expand into other sports like basketball, soccer, combat sports, etc., building on the Ronin chain.",
      "After two months into the launch of private beta, they are witnessing explosive growth while continuing to build the product further.",
    ],
    link: "https://bowled.io/",
  },
  {
    image: bnbchain,
    description: [
      "Build N Build (BNB) Chain is a distributed blockchain network upon which developers and innovators can build decentralized applications (dApps) as part of the move into Web3.",
    ],
    link: "https://www.bnbchain.org/en",
  },
  {
    image: chainstack,
    description: [
      "The leading suite of services connecting developers with Web3 infrastructure, powering applications in DeFi, NFT, gaming, analytics, and everything in between.",
    ],
    link: "https://chainstack.com/",
  },
  {
    image: ethlas,
    description: [
      "At Ethlas, we’re engineering the future of gaming by leveraging Web3 to unlock the next level of experiences. Ethlas is making Web3 accessible and safe for everyone, paving the way towards the future of gaming by using Web3 to unlock innovative and fresh user experiences.",
      "Founded by a group of four tech leaders with extensive experience in leading teams across tech giants such as Google, Microsoft, Airbnb and Grab, etc, Ethlas is headquartered in Singapore with offices in San Francisco Bay Area and New York, United States, and Manila, Philippines.",
      "Within two months of its beta launch and with minimal marketing, Ethlas chalked up more than three million gameplays with over 100,000 unique wallet IDs to its gamepage. Ethlas minted its first Windblown asset-backed NFTs in December 2021, and sold out its first Komo series of NFTs on mint day in March 2022. Today, Ethlas has garnered over 1.5m unique connected wallets and 15 million gameplays.",
      "Ethlas is backed by Sequoia Capital India, Makers Fund, Dragonfly Capital, Yield Guild Games (SEA), Infinity Ventures Crypto, Global Blockchain Innovative Capital, Venturra Capital, and executives from Coinbase, CoinMarketCap, Spartan Group, Hashed and Grab. ",
    ],
    link: "https://ethlas.com/",
  },
  {
    image: genopets,
    description: [
      "Genopets is a free-to-play Web3 mobile pet care game that makes it fun and rewarding to live a healthy lifestyle. Take care of your Genopet by taking care of yourself!",
    ],
    link: "https://www.genopets.me",
  },

  {
    image: genun,
    description: [
      "GENUN is a gaming powerhouse that believes the best building process should be one that fosters intrinsic motivation, so it allows everyone to have agency on what they work on.",
      "Uppercut is the company’s first release, a turn-based fighting game where two players simultaneously queue abilities and movements, represented by cards, to outwit and out-punch their way to victory.",
      "The company will also be releasing PROJECT HERMES and GENUN STREAMS in the coming months.",
    ],
    link: "https://ikigaitech.org/solutions/#/",
  },
  {
    image: gomble,
    description: [
      "Gomble is a seasoned casual game publisher with 150 casual game launches from 111Percent, one of the successful casual game companies, attracting 100M unique global users with a RandomDice: Defense, BBtan series, etc. Leveraging the track records, Gomble is aiming to bring real gamers into Web3 by maximizing the essential entertainment value of the game with Blockchain, providing a fun and addictive game experience.",
    ],
    link: "https://rumbystars.io/",
  },
  {
    image: highstreet,
    description: [
      "Highstreet is a commerce-centered metaverse that integrates shopping with gaming.",
    ],
    link: "https://www.highstreet.market/",
  },
  {
    image: ikigai,
    description: [
      "Ikigai Technologies is a company that unites passionate technologists with diverse projects ranging from a data platform to a creative game studio and a state-of-the-art marketplace — all powered by Web3.",
      "It envisions a world where decentralized technology holds entities accountable and NFTs are the best practices — where minting costs are reasonable and game developers can collaborate freely.",
    ],
    link: "https://ikigaitech.org/",
  },
  {
    image: immutable,
    description: [
      "Immutable is a global leader in gaming on a mission to bring digital ownership to every player by making it safe and easy to build great web3 games.",
    ],
    link: "https://www.immutable.com/",
  },
  {
    image: klaytn,
    description: [
      "Klaytn is a global Layer-1 public blockchain designed to power tomorrow’s on-chain world. With the lowest transaction latency amongst leading blockchains, enterprise-grade reliability, and a developer-friendly environment, Klaytn provides a seamless experience for users and developers.",
      "Since its launch in June 2019, Klaytn has been accelerating blockchain mass adoption through a broad array of use cases from DeFi to the metaverse, gaming, and even the Bank of Korea’s CBDC pilot project. Today, it’s one of the only globally competitive Web 3.0 ecosystems developed in South Korea, generating more than 1 billion transactions from over 300 decentralized applications.",
    ],
    link: "https://klaytn.foundation/",
  },
  {
    image: logosphere,
    description: [
      "Logosphere provides an easy-to-use, flexible, open-source framework for generating application back-ends that are customized to specific business domains. ",
      "This is accomplished with a JSON schema, allowing builders to seamlessly deploy in “one-click” and have the powers of an on-chain, decentralized knowledge graph database at their fingertips.",
    ],
    link: "https://logosphere.io/",
  },
  {
    image: magicblok,
    description: [
      "Magicblock is an infrastructure provider for on-chain mobile games. Core contributor and maintainer of the Solana Unity SDK, Magicblock is a Unity Verified Solution Partner. ",
      "By building state-of-the-art infrastructure and pushing the boundaries of blockchain latency, Magicblock provides game developers with powerful tools to easily enable User-Generated Content (UGC) and User-Generated Logic (UGL) within their games.",
    ],
    link: "https://www.magicblock.gg/",
  },
  {
    image: metaengine,
    description: [
      "MetaEngine exists to give creators all the tools they need to build games and experiences people love.",
    ],
    link: "https://www.metaengine.gg/",
  },
  {
    image: metapals,
    description: [
      "Virtual pets that eat, sleep and play from your browser screen!",
    ],
    link: "https://metapals.pet/",
  },
  {
    image: metapixel,
    description: [
      "METAPIXEL is a Web3 gaming ecosystem, created to usher in a new era of global Web3 gaming on the Aptos blockchain. ",
      "Aiming to elevate the gaming experience with high-end games and a seamless, player-centric interface, METAPIXEL is creating a Web3 environment where the concept of player-owned economies shifts from idea to reality.",
    ],
    link: "https://metapixel.io/",
  },
  {
    image: miraj,
    description: [
      "Mirage is working to power the Web3 gaming ecosystem with its comprehensive, flexible, and accessible game services platform, Mosaic. It also supports and publishes great Web3 games.",
    ],
    link: "https://mirage.xyz/",
  },
  {
    image: mixmob,
    description: [
      "MixMob is a remix game universe defined by one question — what would you do if an A.I. deleted our culture?",
      "Their first title, MixMob: Racer 1, is Mario Kart meets Clash Royale - combining the best of synchronous card strategy and battle racing games. The team previously worked on FIFA, HALO, Battlefield, PokerStars, and more.",
    ],
    link: "https://www.mixmob.io/",
  },
  {
    image: mongodb,
    description: [
      "MongoDB is a developer data platform company empowering innovators to create, transform, and disrupt industries by unleashing the power of software and data. Headquartered in New York, MongoDB has more than 40,800 customers in over 100 countries.",
      "The MongoDB database platform has been downloaded over 365 million times, and there have been more than 1.8 million registrations for MongoDB University courses.",
    ],
    link: "https://www.mongodb.com/",
  },
  {
    image: myria,
    description: [
      "Myria is an Ethereum Layer-2 platform (built with StarkEx) designed to make creating and scaling blockchain games much easier. Combined with an in-house game studio, it’s bringing Web3 gaming to life.",
    ],
    link: "https://myria.com/",
  },
  {
    image: nyan,
    description: [
      "Nyan Heroes is a team based competitive shooter, featuring cats piloting mechs, developed by a team of 60+ AAA industry veterans.",
    ],
    link: "https://nyanheroes.com/",
  },
  {
    image: nofinality,
    description: [
      "OnFinality is a blockchain infrastructure platform that saves web3 builders time and makes their lives easier. OnFinality delivers scalable API endpoints for the biggest blockchain networks and empowers developers to automatically test, deploy, scale and monitor their own blockchain nodes in minutes.",
      "To date, OnFinality has served over hundreds of billions of RPC requests across 80 networks including Avalanche, BNB Chain, Cosmos, Polkadot and Ethereum, and is continuously expanding these mission-critical services so developers can build the decentralised future, faster",
      "Build Smarter with OnFinality.",
    ],
    link: "https://onfinality.io/",
  },
  {
    image: okx,
    description: ["Web3 world at your fingertips."],
    link: "https://www.okx.com/web3",
  },
  {
    image: planetarium,
    description: [
      "Planetarium Labs, a community-driven Web 3 game publisher backed by $32 million in Series A funding from Animoca Brands, focuses on providing a scalable and impactful platform for user-generated content, ownership, and earnings.",
      "The company unites expertise in blockchain development, game publishing, and Web3 content creation to foster strong connections between technology and communities.",
    ],
    link: "https://nine-chronicles.com/",
  },
  {
    image: polygon,
    description: [
      "Polygon Labs develops Ethereum scaling solutions for Polygon protocols. Polygon Labs engages with other ecosystem developers to help make scalable, affordable, secure, and sustainable blockchain infrastructure available for Web3. Polygon Labs has initially developed a growing suite of protocols for developers to gain easy access to major scaling solutions, including Layer-2s (zero-knowledge rollups), sidechains, app-specific chains, and data availability protocols.",
      "Scaling solutions that Polygon Labs initially developed have seen widespread adoption with tens of thousands of decentralized apps, unique addresses exceeding 287 million, over 1.4 million smart contracts created, and 2.7 billion total transactions processed since inception. The existing Polygon network is home to some of the biggest Web3 projects, such as Aave, Uniswap, and OpenSea, and well-known enterprises, including Robinhood, Stripe, and Adobe. Polygon Labs is carbon neutral with the goal of leading Web3 in becoming carbon negative.",
    ],
    link: "https://polygon.technology/",
  },
  {
    image: ronin,
    description: [
      "Ronin is an EVM blockchain crafted for developers building games with player-owned economies.",
    ],
    link: "https://roninchain.com/",
  },
  {
    image: sei,
    description: ["The fastest Layer-1 blockchain."],
    link: "https://www.sei.io/",
  },
  {
    image: skymavis,
    description: [
      "Sky Mavis is on a mission to create digital property rights for all users of the internet, starting with gamers. Sky Mavis is the creator of Axie Infinity, the #1 NFT project by all time NFT trading volume (4.2 B) and users onboarded to Web3.",
      "In addition, the team is also the inventor of Ronin, an EVM Blockchain specifically forged for gaming, which is second only to Ethereum when it comes to NFT sales by volume. Based in Vietnam and Singapore, Sky Mavis has raised funding from investors including Andreessen Horowitz, Paradigm, Libertus, and Mark Cuban.",
    ],
    link: "https://skymavis.com/",
  },
  {
    image: solana,
    description: [
      "Solana is engineered for widespread, mainstream use by being energy efficient, lightning fast, and inexpensive.",
    ],
    link: "https://solana.org/",
  },
  {
    image: staratlas,
    description: [
      "Star Atlas is a space-based gaming metaverse brought together through state-of-the-art graphics, rich gameplay, and the Solana blockchain.",
    ],
    link: "https://staratlas.com/",
  },
  {
    image: sui,
    description: ["Unlock the freedom to build powerful on-chain assets."],
    link: "https://sui.io/about",
  },
  {
    image: supergaming,
    description: [
      "SuperGaming is one of India's leading gaming companies based in Singapore and Pune, India.",
      "Along with building popular mobile games such as MaskGun, Devil Amongst Us, and Tower Conquest, it has invested deeply in building its own gaming engine for running hyperscale, real-time multiplayer games, including the official PAC-MAN game.",
      "SuperGaming is building India's most awaited game Indus, an Indo-Futuristic Battle Royale.",
    ],
    link: "https://www.supergaming.com/",
  },
  {
    image: upland,
    description: [
      "Upland is the biggest Web3 metaverse platform mapped to the real world and one of today's most widely adopted blockchain-based applications. Upland is available to play across devices, including the Apple App Store and Google Play Store.",
      "With its recently launched developer platform, allowing any games or services to connect to Upland's identity, economy, land, and NFT systems, Upland is fast becoming the world's Metaverse Super App.",
    ],
    link: "https://www.upland.me/",
  },
  {
    image: web3auth,
    description: [
      "The simplest Web3 Wallet Infrastructure for anyone and everyone.",
    ],
    link: "https://web3auth.io/",
  },
  {
    image: war,
    description: [
      "A free-to-play, multiplayer, first-person shooter built around season-based content and secured by the Zilliqa blockchain.",
    ],
    link: "https://www.zilliqa.com/",
  },
  {
    image: xpla,
    description: [
      "XPLA is an entertainment content-specialized mainnet that leverages blockchain technology to provide a scalable, secure, and decentralized infrastructure for Web 2.0 and Web 3.0 audiences.",
      "The company aims to facilitate the widespread adoption of blockchain technology by being a digital hub for gaming and entertainment content.",
    ],
    link: "https://xpla.io/",
  },
  {
    image: ygg,
    description: [
      "Yield Guild Games (YGG) is a decentralized gaming guild that empowers its community to build their metaverse résumé through initiatives like Web3 Metaversity and the Guild Advancement Program. YGG supports the Web3 esports scene and has its own team of elite esports players, YGG Elite. ",
      "With a global network of regional guild partners and over 80 blockchain games and infrastructure projects, YGG caters to a rapidly expanding community of gamers and blockchain enthusiasts.",
    ],
    link: "https://www.yieldguild.io/",
  },
  {
    image: ztx,
    description: [
      "ZTX is a Web3 metaverse platform co-created by Jump Crypto and ZEPETO. It offers an immersive 3D open world for users to create, trade, and earn digital assets while enjoying social interaction.",
      "The company aims to empower self-expression through customizable avatars and lands equipped with NFTs created by diverse creators, DAOs, and communities.",
    ],
    link: "https://ztx.io",
  },
];

const Dot = ({ isSelected, onClick }) => (
  <span
    style={{
      display: "inline-block",
      height: "10px",
      width: "10px",
      borderRadius: "50%",
      backgroundColor: isSelected ? "#C8CFDE" : "#4B608D",
      margin: "4px",
      cursor: "pointer",
    }}
    onClick={onClick}
  />
);

const gamingWeek = ({ location }: PageProps) => {
  const [open, setOpen] = useState(false);

  const [videoID, setVideoID] = useState({
    video: "",
    platform: "",
  });
  const [selectedSpeaker, setSelectedSpeaker] = useState(speakers[0]);
  const [selectedCompany, setSelectedCompany] = useState(companies[0]);
  const [currentCompanyIndex, setCurrentCompanyIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleCompanyClick = (company, index) => {
    setSelectedCompany(company);
    setCurrentCompanyIndex(index);
  };

  const handleCardClick = (speaker, index) => {
    setSelectedSpeaker(speaker);
    setCurrentIndex(index);
  };
  const handleOpen = (video: string, platform: string) => {
    setVideoID({
      video: video,
      platform: platform,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setVideoID({
      video: "",
      platform: "",
    });
  };

  const airtableData = useStaticQuery(graphql`
    query Web3EventQuery {
      events: allAirtable(
        filter: {
          queryName: { eq: "Web3Agenda" }
          data: { Live_on_Site: { eq: true } }
        }
        sort: { fields: data___Start, order: ASC }
      ) {
        nodes {
          data {
            Title
            Speakers
            Type
            Start
            End
          }
        }
      }
    }
  `);
  const eventData = airtableData.events.nodes;
  console.log(eventData);

  return (
    <Layout>
      <MetaData
        title="Web3 Gaming Week"
        location={location}
        image="https://jumpcrypto.com/gaming-card.png"
        description="Google Cloud and the Pit are bringing together builders, innovators, and visionaries at the intersection of gaming and Web3. Hosted at Google's Singapore headquarters, this 6 day game-jam will be full of learning, networking and building, and participants will compete for prizes."
        type="website"
      />
      <ArrowComponent></ArrowComponent>
      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "120px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          display: { xs: "none", xl: "block" },
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />
      <Box
        component="div"
        sx={{
          position: "relative",
          maxWidth: 1244,
          mx: "auto",
          pt: { xs: 8, md: 12 },
        }}
      >
        <Box
          component="div"
          sx={{
            height: { xs: 497, md: 558 },
            position: "relative",
            display: "flex",
            alignItems: { xs: "flex-start", md: "center" },
            justifyContent: "center",
            background: "#0C1F47",
            border: "1px solid #4B608D",
            pt: 9,
          }}
        >
          <Box
            component="img"
            src={bg}
            alt=""
            sx={{
              position: "absolute",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <Box
            component="img"
            src={logo}
            alt=""
            sx={{
              position: "relative",
              maxWidth: { xs: 228, sm: 268, md: "initial" },
            }}
          />
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            gap: 2,
            mt: 2.5,
            flexWrap: { xs: "wrap", md2: "nowrap" },
            "& h5": {
              fontSize: 21,
              letterSpacing: "-0.01em",
              lineHeight: 1.1,
            },
          }}
        >
          <Box
            component="div"
            sx={{
              background: "#0C1F47",
              border: "1px solid #4B608D",
              fontWeight: 600,
              textAlign: "center",
              px: 2,
              height: 148,
              width: { xs: "100%", sm: "calc(50% - 10px)", md2: "100%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: { md2: 271 },
              flex: "0 0 auto",
            }}
          >
            <Typography variant="h5">
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                June 12 - 17,
              </Box>{" "}
              2023
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              background: "#0C1F47",
              border: "1px solid #4B608D",
              fontWeight: 600,
              textAlign: "center",
              px: 2,
              height: 148,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 21,
              width: { xs: "100%", sm: "calc(50% - 10px)", md2: "100%" },
              maxWidth: { md2: 333 },
              flex: "0 0 auto",
              letterSpacing: "-0.01em",
            }}
          >
            <Box component="span">
              <Typography
                variant="h5"
                sx={{ color: "#1CE7C2", display: "block", mb: 0.7 }}
              >
                Google Singapore
              </Typography>
              <Link
                component={OutboundLink}
                href="https://goo.gl/maps/7hDZH6pamXWJQEhQ9"
                target="_blank"
                rel="noreferrer"
                sx={{
                  fontSize: 14,
                  textDecoration: "underline",
                  display: "flex",
                  alignItems: "center",
                  gap: 1.2,
                }}
              >
                <LocationIcon /> Google Asia Pacific
              </Link>
            </Box>
          </Box>
          <Box
            component="div"
            sx={{
              background: "#0C1F47",
              border: "1px solid #4B608D",
              px: 3,
              py: { xs: 3, md2: 0 },
              height: { md2: 148 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              width: { xs: "100%", md2: "initial" },
            }}
          >
            <Box component="span">
              <Typography sx={{ fontSize: 14 }}>
                Google Cloud and the Pit are bringing together builders,
                innovators, and visionaries at the intersection of gaming and
                Web3. Hosted at Google's Singapore headquarters, this 6-day
                game-jam will be full of learning, networking and building, and
                participants competing for prizes.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          component="div"
          sx={{
            background: "#0C1F47",
            border: "1px solid #4B608D",
            py: { xs: 3.5, md: 5 },
            px: { xs: 1.5, md: 5, lg: 8.4 },
            mt: 5,
            position: "relative",
            "& h2": { fontSize: 50 },
            backgroundImage: `url(${bg2})`,
            backgroundSize: "cover",
          }}
        >
          <Typography variant="h2" sx={{ position: "relative", zIndex: 2 }}>
            Featured{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              Speakers
            </Box>
          </Typography>
          <Typography
            sx={{ maxWidth: 500, mt: 1.6, position: "relative", zIndex: 2 }}
          >
            Expect to learn from and network with leading Web3 founders,
            builders, and innovators.
          </Typography>
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: { xs: "column-reverse", md: "row" },
              gap: "12px",
            }}
          >
            <Box
              component="div"
              sx={{
                flex: 1,
                width: "100%",
                mt: { xs: 0.5, md: 3.2 },
                mb: 5,
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(2, 1fr)",
                  sm: "repeat(3, 1fr)",
                },
                gap: "14px",
              }}
            >
              {speakers.map((s, index) => (
                <Box
                  component="div"
                  key={s.name}
                  onClick={() => handleCardClick(s, index)}
                  sx={{
                    ...card,
                    backgroundColor: `${
                      selectedSpeaker.name === s.name ? "#0F285B" : "#0C1F47"
                    }`,
                    cursor: "pointer",
                  }}
                >
                  {s.image && (
                    <Box
                      component="img"
                      src={s.image}
                      alt={s.name}
                      sx={{
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        objectFit: "contain",
                        width: { xs: "50%", sm: "initial" },
                      }}
                    />
                  )}
                  <Box component="div">
                    <Typography variant="h6">{s.name}</Typography>
                    <Typography>
                      {s.company.map((c, index) => (
                        <Box key={index} component="span">
                          {c}
                        </Box>
                      ))}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              component="div"
              sx={{
                backgroundColor: "#0F285B",
                border: "1px solid #4b608d",
                paddingTop: { xs: "15px", md: "45px" },
                paddingLeft: { xs: "15px", md: "37px" },
                mt: 3.2,
                mb: { xs: 0, md: 5 },
                width: { xs: "100%", md: "45%" },
                maxWidth: { xs: "inital", mx: "421px" },
                display: "flex",
                flexDirection: "column",
                position: "relative",
                pb: "150px",
                minHeight: { md: 600 },
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  gap: "34px",
                }}
              >
                <Box
                  component="img"
                  src={selectedSpeaker.image}
                  alt={selectedSpeaker.name}
                  sx={{
                    position: "relative",
                    right: 0,
                    bottom: 0,
                    objectFit: "contain",
                    objectPosition: "bottom",
                    width: "117px",
                    height: "141px",
                    background: "#0c1f47",
                    border: "1px solid #4b608d",
                  }}
                />
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#1CE7C2",
                        mb: 0.5,
                        position: "relative",
                      }}
                    >
                      {selectedSpeaker.name}
                    </Typography>
                    <Typography sx={{ fontSize: 10, maxWidth: "136px" }}>
                      {selectedSpeaker.company}
                    </Typography>
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {selectedSpeaker.twitter && (
                      <Link
                        href={selectedSpeaker.twitter}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Twitter />
                      </Link>
                    )}
                    {selectedSpeaker.linkedin && (
                      <Link
                        href={selectedSpeaker.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkedIn />
                      </Link>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                component="div"
                sx={{
                  fontSize: 14,
                  marginTop: "37px",
                  maxWidth: "90%",
                  color: "#F9F7F4",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingBottom: { xs: "50px", md: "0px" },
                }}
              >
                {selectedSpeaker.description.map((c, index) => (
                  <Typography key={index}>{c}</Typography>
                ))}
              </Box>
              <Box
                component="div"
                sx={{
                  position: "absolute",
                  bottom: { xs: "10px", md: "38px" },
                  left: 0,
                  right: 0,
                  marginInline: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "300px",
                }}
              >
                {speakers.map((_, index) => (
                  <Dot
                    key={index}
                    isSelected={index === currentIndex}
                    onClick={() => handleCardClick(speakers[index], index)}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          component="div"
          sx={{
            display: "grid",
            gridTemplateColumns: { md: "repeat(2, 1fr)" },
            gap: 3.2,
            py: 11,
          }}
        >
          <Box
            component="div"
            sx={{
              px: { xs: 3.5, md: 5.7 },
              py: { xs: 4.5, md: 4.8 },
              background: "#0C1F47",
              border: "1px solid #4B608D",
              position: "relative",
              backgroundImage: `url(${bg3})`,
              backgroundSize: "cover",
            }}
          >
            <Box
              component="img"
              src={google}
              alt=""
              sx={{ display: "block", maxWidth: "100%", mt: { md: 1.2 } }}
            />
            <Typography sx={{ mb: 2, mt: 3.2 }}>
              Google Cloud accelerates Web3 organizations’ ability to digitally
              transform their business by delivering enterprise-grade solutions
              that leverage Google's cutting-edge technology – all on the
              cleanest cloud in the industry.
            </Typography>
            <Typography sx={{ mb: 3.6 }}>
              Customers in more than 200 countries and territories turn to
              Google Cloud as their trusted partner to enable growth and solve
              their most critical business problems.
            </Typography>
            <Button
              component={OutboundLink}
              href="https://cloud.google.com/"
              target="_blank"
              rel="noreferrer"
              variant="outlined"
              endIcon={<Arrow />}
            >
              .Learn More
            </Button>
          </Box>
          <Box
            component="div"
            sx={{
              px: { xs: 3.5, md: 5.7 },
              py: { xs: 4.5, md: 4.8 },
              background: "#0C1F47",
              border: "1px solid #4B608D",
              backgroundImage: `url(${bg4})`,
              backgroundSize: "cover",
            }}
          >
            <Box
              component="img"
              src={pit}
              alt=""
              sx={{ display: "block", maxWidth: "100%" }}
            />
            <Typography sx={{ mb: 3.6, mt: 3.2 }}>
              Designed by Jump Crypto to foster connections and inspire, the Pit
              brings together passionate developers, artists, founders, and
              everyone in between to help build the future of Web3.
            </Typography>

            <Button
              component={RouterLink}
              to="/thepit"
              variant="outlined"
              endIcon={<Arrow />}
            >
              .Learn More
            </Button>
          </Box>
        </Box>

        <Box
          component="div"
          sx={{
            background: "#0C1F47",
            border: "1px solid #4B608D",
            py: { xs: 3.5, md: 5 },
            px: { xs: 3.5, md: 5, lg: 8.4 },
            position: "relative",
            backgroundImage: `url(${bg5})`,
            backgroundSize: "cover",
            "& h2": { fontSize: 50 },
          }}
        >
          <Typography variant="h2" sx={{ position: "relative", zIndex: 2 }}>
            In Good{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              Company
            </Box>
          </Typography>
          <Typography
            sx={{ maxWidth: 500, mt: 1.6, position: "relative", zIndex: 2 }}
          >
            Connect with leading Web3 brands and leverage an entire week of
            networking opportunities to further your goals.
          </Typography>
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: { xs: "initial", md: "flex-start" },
              flexDirection: { xs: "column-reverse", md: "row" },
              gap: "23px",
              marginTop: "50px",
            }}
          >
            <Box
              component="div"
              sx={{
                flex: 1,
                mt: { xs: 0.5, md: 1 },
                mb: { xs: 0, md: 14 },
                display: "grid",
                gap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(3, 1fr)",
                },
                zIndex: 0,
              }}
            >
              {companies.map((c, index) => (
                <Box
                  component="div"
                  key={index}
                  onClick={() => handleCompanyClick(c, index)}
                  sx={{
                    width: { lg: "209px" },
                    maxWidth: { lg: "209px" },
                    px: "10px",
                    height: "100px",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      ...projectBox,
                      backgroundColor: `${
                        selectedCompany.link === c.link ? "#0F285B" : "#0C1F47"
                      }`,
                      cursor: "pointer",
                    }}
                    component="div"
                  >
                    <Box component="img" src={c.image} sx={projectBoxLogo} />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              component="div"
              sx={{
                backgroundColor: "#0F285B",
                border: "1px solid #4b608d",
                paddingTop: { xs: "15px", md: "45px" },
                paddingLeft: { xs: "15px", md: "37px" },
                pb: "150px",
                minHeight: { xs: "initial", md: "550px" },
                width: { xs: "100%", md: "410px" },
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "90%",
                }}
              >
                <Box
                  component="img"
                  src={selectedCompany.image}
                  sx={{
                    ...projectBoxLogo,
                  }}
                />
                {selectedCompany.link && (
                  <Link
                    href={selectedCompany.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkIcon />
                  </Link>
                )}
              </Box>
              <Box
                component="div"
                sx={{
                  fontSize: 14,
                  marginTop: "20px",
                  maxWidth: "90%",
                  color: "#F9F7F4",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingBottom: { xs: "50px", md: "0px" },
                }}
              >
                {selectedCompany.description.map((c, index) => (
                  <Typography component="div" key={index}>
                    {c}
                  </Typography>
                ))}
                {selectedCompany.link === "https://ztx.io" && (
                  <Box component="div">
                    To learn more about us, please visit ut at{" "}
                    <Link
                      style={{ textDecoration: "underline" }}
                      href="https://ztx.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ztx.io
                    </Link>{" "}
                    and{" "}
                    <Link
                      style={{ textDecoration: "underline" }}
                      href="https://twitter.com/ztxofficial"
                      target="_blank"
                      rel="noreferrer"
                    >
                      twitter.com/ztxofficial
                    </Link>
                  </Box>
                )}
              </Box>
              <Box
                component="div"
                sx={{
                  position: "absolute",
                  bottom: { xs: "10px", md: "28px" },
                  left: 0,
                  right: 0,
                  marginInline: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "300px",
                }}
              >
                {companies.map((_, index) => (
                  <Dot
                    key={index}
                    isSelected={index === currentCompanyIndex}
                    onClick={() => handleCompanyClick(companies[index], index)}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box component="div" sx={{ position: "relative", pb: { xs: 6, md: 12 } }}>
        <Box
          component="div"
          sx={{
            position: "absolute",
            right: "-30px",
            top: 0,
            width: "calc(100% + 30px)",
            height: { xs: "40%", sm: "100%" },
          }}
        >
          <StaticImage
            src="../../images/pit/prizesbg.svg"
            alt="High stakes"
            placeholder="blurred"
            layout="fullWidth"
            objectPosition="center center"
            objectFit="contain"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
        <Box
          component="div"
          sx={{
            position: "relative",
            maxWidth: 1244,
            mx: "auto",
          }}
        >
          <Box
            component="div"
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: { xs: 6, md: 12 },
              paddingBottom: 5.8,
              letterSpacing: "3%",
              overflow: "visible",
            }}
          >
            <Typography variant="h2" sx={{ position: "relative", zIndex: 2 }}>
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                High Stakes
              </Box>
            </Typography>
            <Typography
              sx={{
                maxWidth: 650,
                mt: 1.6,
                position: "relative",
                zIndex: 2,
                textAlign: "center",
              }}
            >
              Collaborate and compete with fellow attendees for a chance to be
              awarded prizes and partner awards. You can apply to join as a
              group or form a team at the event. <br />
              <span style={{ fontWeight: 600 }}>
                Every idea must begin at the game jam, no pre-existing work will
                be admitted.
              </span>
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              background: "#0C1F47",
              border: "1px solid #4B608D",
              py: { xs: 3, md: 4.3 },
              px: { xs: 1, md: 2 },
              position: "relative",
              marginInline: "auto",
              flexDirection: "column",
              mb: { xs: 8, md: 12.5 },
              maxWidth: "808px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "& h2": { fontSize: 50 },
            }}
          >
            <Typography
              sx={{
                mt: 1.6,
                position: "relative",
                zIndex: 2,
                textAlign: "center",
                pb: 3.9,
              }}
            >
              Your pitch will be evaluated and scored based on a points-based
              rubric, within the following equally-weighted categories:{" "}
              <span style={{ fontWeight: 600 }}>
                Technical Demo, Novelty, Presentation, Web3 Integration.
              </span>
            </Typography>
            <Typography
              sx={{
                fontSize: "21px",
                letterSpacing: "-1%",
                fontWeight: 600,
                position: "relative",
                zIndex: 2,
                textAlign: "center",
                pb: 3.7,
              }}
            >
              Evaluation Rubric:{" "}
              <span style={{ color: "#1CE7C2" }}>20 points total</span>
            </Typography>
            <Box
              component="div"
              sx={{
                position: { xs: "relative", sm: "absolute" },
                marginInline: "auto",
                bottom: { xs: "auto", sm: "-33px" },
                left: "0",
                right: "0",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                gap: { xs: "10px", sm: "auto" },
                alignItems: "center",
                width: "90%",
              }}
            >
              <Box
                component="div"
                sx={{
                  position: "relative",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "67px",
                  background: "#0C1F47",
                  border: "1px solid #4B608D",
                  width: { xs: "100%", sm: "23%" },
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  Technical Demo
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                    color: "#1CE7C2",
                  }}
                >
                  5 points
                </Typography>
              </Box>
              <Box
                component="div"
                sx={{
                  position: "relative",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "67px",
                  background: "#0C1F47",
                  border: "1px solid #4B608D",
                  width: { xs: "100%", sm: "23%" },
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  Novelty of Idea
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                    color: "#1CE7C2",
                  }}
                >
                  5 points
                </Typography>
              </Box>
              <Box
                component="div"
                sx={{
                  position: "relative",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "67px",
                  background: "#0C1F47",
                  border: "1px solid #4B608D",
                  width: { xs: "100%", sm: "23%" },
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  Web3 integration
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                    color: "#1CE7C2",
                  }}
                >
                  5 points
                </Typography>
              </Box>
              <Box
                component="div"
                sx={{
                  position: "relative",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "67px",
                  background: "#0C1F47",
                  border: "1px solid #4B608D",
                  width: { xs: "100%", sm: "23%" },
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  Pitch
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    textAlign: "center",
                    color: "#1CE7C2",
                  }}
                >
                  5 points
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            component="div"
            sx={{
              position: "relative",
              marginInline: "auto",
              maxWidth: "808px",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xs: "20px", sm: "auto" },
            }}
          >
            <Box component="div" sx={prizeBox}>
              <Typography
                sx={{
                  fontSize: "16px",
                  position: "relative",
                  zIndex: 2,
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                1st Prize
              </Typography>
              <Typography
                sx={{
                  fontSize: "42px",
                  position: "relative",
                  zIndex: 2,
                  textAlign: "center",
                  color: "#1CE7C2",
                  fontWeight: 600,
                }}
              >
                $25k
              </Typography>
            </Box>
            <Box component="div" sx={prizeBox}>
              <Typography
                sx={{
                  fontSize: "16px",
                  position: "relative",
                  zIndex: 2,
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                2nd Prize
              </Typography>
              <Typography
                sx={{
                  fontSize: "42px",
                  position: "relative",
                  zIndex: 2,
                  textAlign: "center",
                  color: "#1CE7C2",
                  fontWeight: 600,
                }}
              >
                $15k
              </Typography>
            </Box>
            <Box component="div" sx={prizeBox}>
              <Typography
                sx={{
                  fontSize: "16px",
                  position: "relative",
                  zIndex: 2,
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                3rd Prize
              </Typography>
              <Typography
                sx={{
                  fontSize: "42px",
                  position: "relative",
                  zIndex: 2,
                  textAlign: "center",
                  color: "#1CE7C2",
                  fontWeight: 600,
                }}
              >
                $10k
              </Typography>
            </Box>
            <Box component="div" sx={prizeBox}>
              <Typography
                sx={{
                  fontSize: "16px",
                  position: "relative",
                  zIndex: 2,
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Partner awards
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  position: "relative",
                  zIndex: 2,
                  textAlign: "center",
                  color: "#1CE7C2",
                  fontWeight: 600,
                }}
              >
                COMING
                <br />
                SOON
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          textAlign: "center",
          pb: { xs: 8, md: 13 },
          maxWidth: 897,
          mx: "auto",
        }}
      >
        <Typography sx={{ mb: 3 }} variant="h2">
          What to{" "}
          <Box component="span" sx={{ color: "#1CE7C2" }}>
            Expect
          </Box>
        </Typography>
        <Typography sx={{ maxWidth: 580, mx: "auto" }}>
          With this event, Google Cloud and the Pit are building on the success
          of Polygon at the Pit, where hundreds of developers came together for
          two weeks to learn, network, and build. See for yourself.
        </Typography>

        <Box
          component="div"
          sx={{
            border: "1px solid #4B608D",
            position: "relative",
            mt: 5.4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "56.25% 0 0 0",
            cursor: "pointer",
          }}
          role="button"
          onClick={() => {
            handleOpen("1anzxJcWP9M", "youtube");
          }}
        >
          <Box
            component="img"
            src={play}
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              zIndex: 2,
            }}
          />

          <StaticImage
            src="../../images/pit/polygon.png"
            alt=""
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>

      <StaticImage
        src="../../images/brandLines.svg"
        alt=""
        placeholder="blurred"
        style={{
          position: "absolute",
          right: "0",
          bottom: "0",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
      <Modal open={open} onClose={handleClose} sx={modalStyle}>
        <Box component="div" sx={videoStyle}>
          <Button
            onClick={handleClose}
            sx={{
              width: 30,
              height: 30,
              border: "1px solid #fff",
              borderRadius: "50%",
              position: "absolute",
              right: -15,
              top: -15,
              display: "block",
              zIndex: 2,
              backgroundColor: "#fff",
              svg: {
                fill: "#000",
                transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
              },
              "&:hover": {
                backgroundColor: "#fff",
                svg: {
                  transform: "scale(1.25)",
                  fill: "red",
                },
              },
            }}
          >
            <CloseIcon />
          </Button>
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            {videoID.platform === "vimeo" ? (
              <iframe
                src={`https://player.vimeo.com/video/${videoID.video}?h=773c1a597b&autoplay=1&loop=1&color=ffffff&title=0&byline=0&portrait=0`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            ) : (
              <iframe
                src={`https://www.youtube.com/embed/${videoID.video}?autoplay=1`}
                title=""
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
          </div>
        </Box>
      </Modal>
    </Layout>
  );
};

export default gamingWeek;
